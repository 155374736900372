const artistsList = [
  {
    name: "Kamu",
    files: "Kamu",
    fullName: "Kamu",
    location: "Saratoga",
    pic: "https://sacredarthawaii.com/images/artists/Kamu.jpg",
    about:
      "Aloha, my name is Kamu.  I'm from the island of Kaua'i. Now tattooing in Waikiki, Hawaii. I've been tattooing for five years. Black is my favorite color! Polynesian, dotwork, ornamental, lettering are a few of my favorite things! I'm also capable of doing color and black and gray. Mahalo for lookin!",
    instagram: "",
    email: "",
    facebook: "",
    url: "/gallery/kamu",
  },
  {
    name: "Mike",
    files: "Mike",
    fullName: "Mike Hatfield",
    location: "Saratoga",
    pic: "https://sacredarthawaii.com/images/artists/Mike.jpeg",
    about:
      "Born long ago, in a galaxy far, far away (Pontiac, Michigan). Served in the U.S. Air Force, got stationed in Hawaii in 1980. Following the military, spent 10 years airbrushing T-shirts in Waikiki. Graduated in painting surfboards and automotive, and still live painting for fun. When not work, main interests are surfing, and more surfing. Wasn't born in Hawaii, but was born FOR Hawaii.I like to be able to do any tattoo that walks through the door, but consider my specialties to be portraiture/realism,Japanese style, and also Polynesian. I consider myself lucky to be tattooing, and to be able to do art for a living. Spreading aloha daily!",
    instagram: "https://www.instagram.com/mikehtattoo",
    facebook: "",
    email: "",
    url: "/gallery/mike",
  },
  {
    name: "2Bit",
    files: "2Bit",
    fullName: "Chris Curren, aka 2Bit",
    location: "Saratoga",
    pic: "https://sacredarthawaii.com/images/artists/2Bit.jpg",
    about:
      "Chris Curren, aka 2Bit. Originally from Ventura, CA. Moved to Hawaii in 2009 for Bullhead city, AZ and apprenticed under Kevin Read. Been tattooing and piercing for over a decade. Enjoys tattooing traditional, neotraditional, lettering, Polynesian tribal, color realism, black and grey realism, and watercolor. Pretty much everything except for portraits.",
    instagram: "https://www.instagram.com/Tat2bit/",
    email: "",
    facebook: "",
    url: "/gallery/2bit",
  },
  {
    name: "Kaina",
    files: "Kaina",
    fullName: "Kaina Valdez",
    location: "Saratoga",
    pic: "https://sacredarthawaii.com/images/artists/Kaina.jpg",
    about:
      "Aloha, my name is Kaina. I was born in Lahaina, Maui but recently relocated to Waikiki due to the fire disaster on Maui. I do a lot of tribal, so it becomes more favorable to me but I enjoy all styles. I can tattoo anything, black and grey, color, realism, minimal fine lining. Tell me your ideas so we can give you the tattoo you've been wanting to get all your life.",
    instagram: "https://www.instagram.com/kainatattoos/",
    email: "",
    facebook: "",
    url: "/gallery/kaina",
  },
  {
    name: "Tim",
    files: "Timothy",
    fullName: "Tim Turnbull",
    location: "Saratoga",
    pic: "https://sacredarthawaii.com/images/artists/Timothy.jpg",
    about:
      "Hailing from Portland, OR , Timothy specializes in traditional tattooing with a wide range of versatility . Included but not limited to: American traditional , black & grey work, sacred geometry, fine line, lettering , traditional Japanese & neotraditional. His favorite thing about tattooing is taking someone’s idea and creating something that brings their wildest imagination to fruition. In his free time Timothy enjoys long walks on the beach, exquisite candle lit dinners & reading poetry.",
    instagram: "https://www.instagram.com/tvttxxs/",
    email: "",
    facebook: "",
    url: "/gallery/tim",
  },
  {
    name: "Makua",
    files: "Makua",
    fullName: "Makua Annon",
    location: "Saratoga",
    pic: "https://sacredarthawaii.com/images/artists/makua.jpg",
    about:
      "In the heart of tattoo artistry beats the soul of Makua Annon, a seasoned artisan with over a decade of mastery in the craft.  Steeped in the rich history of Polynesian artistry, Makua's work speaks volumes of cultural legacy and personal journey. Each stroke of his needle tells a story—a saga of the islands, etched in the skin with timeless elegance. From Hawaii tourists seeking a piece of paradise to locals painting their life's tales in ink, Makua welcomes all. Step into his world, where the needles sing, and your story awaits its canvas.",
    instagram: "https://www.instagram.com/808makua",
    email: "",
    facebook: "",
    url: "/gallery/makua",
  },
  {
    name: "Isaac",
    files: "Isaac",
    fullName: "Isaac",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/isaac-pic.jpg",
    about: "Check out my artwork below.",
    instagram: "",
    email: "",
    facebook: "",
    url: "/gallery/isaac",
  },
];

export default artistsList;
